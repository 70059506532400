export const $white = '#FFFFFF';
export const $border = '#D2D6DA';
export const $background = '#F4F4F4';
export const $bordered = '#DCDCDC';
export const $shadow = '0px 8px 40px rgba(0, 0, 0, 0.16)';
export const $tartRed = '#FFEBEB';
export const $primary = '#F25858';
export const $primaryFade = '#FF7D7D';
export const $primaryLight = '#FFEBEB';
export const $primaryText = '#04080F';
export const $error = '#EF2E2E';
export const $success = '#1CA355';
export const $secondaryText = '#77838F';
export const $info = '#33B6DF';
export const $primaryBackground = '#FFEBEB';
export const $grayScale40Black = '#A4ADB4';
export const $grayScale60Black = '#77838F';
export const $grayScale15Black = '#E8E8E8';
export const $systemAlert = '#FFC53A';
export const $robinBlue = '#26A6A6';
export const $forestGreen = '#248232';
