import { flow, getParent, types } from 'mobx-state-tree';
import placeholder from '../assets/img/image-placeholder.svg';
import { kcpAPI, ROUTE_PATHS } from '../shared';
import { objectIdToNumber } from '../shared/utils/convertor';
import { get } from '../utils';

export const ThankYouPageStore = types
    .model('ThankYouPageStore', {
        pending: true,
        errorMessage: types.maybeNull(types.string),
        isCustomerExists: false,
        customerInfoPending: false,
        orderId: types.maybeNull(types.string),
        cart: types.maybeNull(types.frozen())
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        },
        get itemsInOrder() {
            return self.cart?.bags || [];
        },
        get gsImage() {
            return self.cart?.bags[0]?.items[0]?.image || [];
        },
        get orderNumber() {
            if (self.cart?.bags?.length > 1) {
                return self.cart.bags.map((item) => item.orderNumber).join(', ');
            }
            return self.cart?.bags[0]?.orderNumber || '';
        },
        get gsClaims() {
            return self.cart?.gsClaims || 0;
        },
        get metadata() {
            return self.cart?.bags[0]?.items[0]?.metadata || [];
        },
        get getProductTypes() {
            return self.cart?.bags.reduce(
                (acc, bag) => {
                    bag.items.map((item) => {
                        const isGs = item.metadata?.find((item) => item.name === 'suiteId');
                        if (isGs) {
                            acc.gs.push(item.productId);
                        } else {
                            acc.marketplace.push(item.productId);
                        }
                    });
                    return acc;
                },
                { marketplace: [], gs: [] }
            );
        }
    }))
    .actions((self) => {
        const setPending = (pending) => {
            self.pending = pending;
        };

        const setError = (error) => {
            if (error && error.response && error.response.data) {
                self.errorMessage = error.response.data.message;
                return true;
            }

            if (error && error.message) {
                self.errorMessage = error.message;
            }
        };

        const processCheckoutOrder = flow(function* processCheckoutOrder({ code, onAfterPurchase = () => null }) {
            try {
                const response = yield self.root.post(kcpAPI.orders.createCheckoutOrder, { code });

                const data = yield self.root.ordersStore.getOrdersByCode(code);
                yield self.root.suiteStore.getCurrentSuite(false);
                const cartOrders = get(data, 'cart.bags', []);
                const ordersNumbers = cartOrders.map(({ orderNumber }) => orderNumber);
                const imageSrc = get(cartOrders, '[0].items[0].image', placeholder);

                setCart(data.cart);

                if (response && response.ok) {
                    const orderData = (cartOrders || []).map((bag) => {
                        const merchantId = get(bag, 'merchantId', '');
                        const source = get(bag, 'source', 'v1');
                        const accountId = source === 'v2' ? `${objectIdToNumber(merchantId)}` : merchantId;

                        return {
                            accountId,
                            klicklyOrderId: get(bag, 'klicklyOrderId', ''),
                            email: get(data, 'cart.customerInfo.email', ''),
                            cartId: get(data, 'cart._id', ''),
                            shopDomain: get(bag, 'shopDomain', ''),
                            brandName: get(bag, 'merchantName', ''),
                            orderId: get(bag, 'klicklyOrderId', ''),
                            orderValue: get(bag, 'priceDetails.subTotal'),
                            orderTax: get(bag, 'priceDetails.taxes'),
                            orderShipping: get(bag, 'priceDetails.shipping'),
                            campaignName: get(bag, 'campaigns[0]'),
                            items: bag.items.map((item) => ({
                                productName: item.productTitle,
                                productId: source === 'v2' ? `${objectIdToNumber(item.productId)}` : item.productId,
                                qty: item.qty,
                                price: item.price
                            })),
                            version: source
                        };
                    });

                    self.root.trackingStore.sendEvent({
                        type: 'event',
                        name: 'purchase',
                        data: orderData
                    });
                }

                onAfterPurchase({ cart: data.cart, ordersNumbers, imageSrc });

                setPending(false);
            } catch (e) {
                setError(e);
            }
        });

        const processGiftSuiteOrder = flow(function* processGiftSuiteOrder(data) {
            try {
                yield self.root.post(kcpAPI.orders.createCheckoutOrder, data);
                yield self.root.suiteStore.getCurrentSuite(false);
                setPending(false);
            } catch (e) {
                setError(e);
            }
        });

        const openShopPage = () => {
            self.root.routeNavigate(self.root.navigationStore.getLinkToMarketPlace());
            self.root.suiteStore.getCurrentSuite();
        };

        const checkIfCustomerExists = flow(function* checkIfCustomerExists(email) {
            if (!self.root.accountStore.isLoggedIn) {
                self.customerInfoPending = true;
                try {
                    const data = yield self.root.get(kcpAPI.customer.exists, {
                        email
                    });
                    self.isCustomerExists = data.exists;
                    self.customerInfoPending = false;
                } catch (error) {
                    if (error.response) {
                        self.error = error.response.data.message;
                    }
                    self.customerInfoPending = false;
                }
            } else {
                self.isCustomerExists = true;
            }
        });

        const applyFit = (orderId) => {
            self.orderId = orderId;
            return self.root.routeNavigate(ROUTE_PATHS.onboarding);
        };

        const setCart = (cart) => {
            self.cart = cart;
        };

        return {
            processCheckoutOrder,
            processGiftSuiteOrder,
            openShopPage,
            setPending,
            checkIfCustomerExists,
            applyFit,
            setCart
        };
    });
