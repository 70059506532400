import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Icon } from '@klickly/front-packages';
import { observer } from 'mobx-react';
import { ROUTE_PATHS } from '../../shared';

const RewardRedirect = observer(() => {
    const navigate = useNavigate();
    const { uuid } = useParams();

    useEffect(() => {
        document.body.dataset.klpixelOmitEventView = 'true';

        if (uuid) {
            navigate(ROUTE_PATHS.rewardsApply, { state: { uuid } });
        } else {
            navigate(ROUTE_PATHS.challenges);
        }

        return () => {
            delete document.body.dataset.klpixelOmitEventView;
        };
    }, []);

    return <Icon.Loader />;
});

export default RewardRedirect;
