import { objectIdToNumber } from './convertor';

export const buildPromotionData = (promotion, additionalData = {}) => ({
    _id: promotion.source === 'v2' ? `${objectIdToNumber(promotion._id)}` : promotion._id,
    externalId: promotion.externalId,
    title: promotion.title,
    accountId: promotion.source === 'v2' ? `${objectIdToNumber(promotion.account)}` : promotion.account,
    campaignId: promotion.source === 'v2' ? `${objectIdToNumber(promotion.campaign)}` : promotion.campaign,
    campaignName: promotion.campaignName,
    shopDomain: promotion.shopDomain,
    brandName: promotion.brandName,
    version: promotion.source || 'v1',
    ...additionalData
});
