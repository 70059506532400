import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CheckoutPDP, CheckoutPDPHTTPWrapper, CloseXIcon, Icon } from '@klickly/front-packages';
import { inject, observer } from 'mobx-react';
import { get } from '../../../utils';
import { remove as removeEl } from '../../../utils/elements/common';
import { loadPixels } from '../../../utils/pixels';
import { NOTIFICATION_MESSAGES, PDP_REDIRECTS } from '../../constants';
import { useRewardAndPointsModal } from '../../hooks';
import { useProductReviewNotification } from '../../hooks/useProductReviewNotification';
import { ROUTE_PATHS } from '../../index';
import { parseQuery } from '../../utils';
import { getMetadata } from '../../utils/metadata';
import RecommendedPromotions from './components/RecommendedPromotions';
import ShareProduct from './components/ShareProduct';
import { WishlistButton } from './components/WishlistButton';
import { CUSTOMIZATIONS_MAP, POINTS_CUSTOMIZATIONS } from './constants';
import { useHandleBuyNow, useOnProductReviewSubmit, usePdpNotifications } from './hooks';
import { CloseCheckoutButtonWrap, StyledButton, StyledButtonWrap, Styles, WrapStyles } from './styles';
import { getEndpoints } from './utils';

export const PDPComponent = inject('store')(
    observer(
        ({
            store,
            extraContent,
            closeIcon = <CloseXIcon />,
            from,
            customButtons,
            withoutHeader = false,
            onAfterAddToCartModalClose,
            onVariantChangeCallback = () => null,
            onBrandNameButtonClick,
            customizationsKey: pdpCustomizationKey,
            inModal = true,
            id,
            onClose,
            rewardUuid
        }) => {
            const navigate = useNavigate();
            const [selectedVariantId, setSelectedVariantId] = useState(null);
            const { account, isLoggedIn, isApprovedCustomer } = store.accountStore;
            const { getShoppingCartState } = store.shoppingCartStore;
            const { customizationsKey: customizeKey, closePDP, productId, isPageReloaded } = store.pdpStore;
            const customizationsKey = pdpCustomizationKey || customizeKey;
            const { completeTask, addToCartTask } = store.tasksStore;
            const taskId = addToCartTask?._id;
            const isAddToCartTaskActive = addToCartTask?.data?.status === 'active';
            const endpoints = useMemo(() => getEndpoints(id || productId), [id || productId]);
            const pointsCustomizations = isLoggedIn ? POINTS_CUSTOMIZATIONS.loggedIn : POINTS_CUSTOMIZATIONS.loggedOut;
            const withUpsellCheck = isLoggedIn && !store.ordersStore.orders?.length;
            const handleBuyNow = useHandleBuyNow(store, isLoggedIn, withUpsellCheck);
            const location = useLocation();

            const { openRewardModal, openPointsModal } = useRewardAndPointsModal({ isLoggedIn });

            useEffect(() => {
                return () => {
                    removeEl('kl_tw_iframe');
                };
            }, []);

            useEffect(() => {
                if (id || productId) {
                    store.recommendedPromotionsStore.getRecommendedPromotions(id || productId);
                }
            }, [id, productId]);

            useEffect(() => {
                // Need to reload page on browser 'back' button click
                // to avoid issues with fetch product data
                const reloadPage = (evt) => {
                    if (evt.persisted) {
                        window.location.reload();
                    }
                };

                window.addEventListener('pageshow', reloadPage);

                return () => {
                    window.removeEventListener('pageshow', reloadPage);
                };
            }, [location.pathname]);

            const handleOnClose = () => {
                if (typeof onClose === 'function') onClose();
                else closePDP(customizationsKey !== 'onboarding');
                return getShoppingCartState();
            };

            const metadata = getMetadata();
            const referrer = parseQuery()?.referrer;

            if (referrer) {
                metadata.push({
                    name: 'customerReferrerId',
                    value: referrer
                });
            }

            const customizations = {
                ...CUSTOMIZATIONS_MAP[customizationsKey],
                ...pointsCustomizations,
                reviews: { showReviews: true }
            };

            // useCloseCachedPdpOnSafari(handleOnClose);
            /* 09.08.2022
             * useCloseCachedPdpOnSafari hook is needed to close pdp page on Safari
             * According to new requirements from Xavier it is not needed anymore
             * But all we know that it could be changed any time :)
             * Use this hook in case when PDP should be closed after go back from checkout page
             * */
            const { onAddToCartTaskComplete, isBuyProductTask } = usePdpNotifications(store, from);

            const handleAddToCartClick = async (data, trackingData) => {
                const price = get(data, 'prices.price', 0);
                const productExternalId = get(trackingData, 'externalId');
                const itemName = get(trackingData, 'title');
                const qty = get(data, 'qty');
                const pixels = get(trackingData, 'pixels', []);

                store.trackingStore.trackAddToCart(trackingData, { price, qty });

                if (customizationsKey === 'onboarding') {
                    store.trackingStore.sendEvent({
                        type: 'event',
                        name: 'Onboarding_Step2_AddToCart'
                    });
                }

                if (customizationsKey === 'rewards') {
                    store.trackingStore.sendEvent({
                        type: 'event',
                        name: 'RewardsPage_CouponPDP_AddToCart'
                    });
                }

                if (isApprovedCustomer && isAddToCartTaskActive && taskId) {
                    completeTask({
                        taskPayload: { productId: id || productId },
                        taskId,
                        taskCompleted: isAddToCartTaskActive,
                        onAfterSuccess: onAddToCartTaskComplete
                    });
                }

                await store.trackingStore.sendAddToCartPostMessage({
                    pixels,
                    data: {
                        price,
                        productExternalId,
                        itemName,
                        qty
                    }
                });

                return getShoppingCartState();
            };

            const handleBeforeBuyNowButtonClick = async (payload, selectedVariant, trackingData) => {
                const price = get(payload, 'prices.price', 0);
                const productExternalId = get(trackingData, 'externalId');
                const itemName = get(trackingData, 'title');
                const qty = get(payload, 'qty');
                const pixels = get(trackingData, 'pixels', []);

                store.trackingStore.trackAddToCart(trackingData, { price, qty });

                await store.trackingStore.sendAddToCartPostMessage({
                    pixels,
                    data: {
                        price,
                        productExternalId,
                        itemName,
                        qty
                    },
                    delayTime: 500
                });
            };

            const handleBuyNowClick = (cartId, checkoutKey) => {
                if (customizationsKey === 'rewards') {
                    store.trackingStore.sendEvent({
                        type: 'event',
                        name: 'RewardsPage_CouponPDP_BuyNow'
                    });
                }

                return handleBuyNow(cartId, checkoutKey);
            };

            const handleGoToShoppingCartClick = () => {
                if (customizationsKey === 'onboarding') {
                    store.trackingStore.sendEvent({
                        type: 'event',
                        name: 'Onboarding_Step2_LeftToCart'
                    });
                }

                closePDP();

                getShoppingCartState(() =>
                    navigate(ROUTE_PATHS.shoppingCart, {
                        state: isBuyProductTask ? store.tasksStore?.shopSuiteTask?.alias : null
                    })
                );
            };

            const handleProductFetchSuccess = (promotion) => {
                if (isPageReloaded) {
                    store.trackingStore.trackViewPromotion(promotion);
                }
                store.pdpStore.setPromotion(promotion);

                if (!promotion?.pixels) return;

                loadPixels(promotion.pixels);
            };

            const handleClickThroughClick = (promotion, redirectToBrandLink) => {
                store.trackingStore.trackClick(promotion, redirectToBrandLink);
            };

            const handleVariantChange = (data = {}, ...props) => {
                const { externalId } = data;
                setSelectedVariantId(externalId);
                onVariantChangeCallback(data, ...props);
            };

            return (
                <Styles>
                    {!withoutHeader ? (
                        <CloseCheckoutButtonWrap>
                            <StyledButton type='unstyled' onClick={handleOnClose}>
                                <Icon size='24px' component={() => closeIcon} />
                            </StyledButton>
                            <StyledButtonWrap>
                                <ShareProduct promotionId={id || productId} />
                                <WishlistButton promotionId={id || productId} selectedVariantId={selectedVariantId} />
                            </StyledButtonWrap>
                        </CloseCheckoutButtonWrap>
                    ) : null}
                    <WrapStyles $withoutHeader={withoutHeader}>
                        <CheckoutPDPHTTPWrapper
                            links={{
                                productFetch: endpoints.productFetchURL,
                                buyNow: endpoints.buyNowURL,
                                cartItemsManage: endpoints.cartItemsManageURL,
                                reviewsFetch: endpoints.productReviewsFetchURL,
                                reviewsWrite: endpoints.reviewsWriteURL
                            }}
                            onClickThroughClick={handleClickThroughClick}
                            onProductFetchSuccess={handleProductFetchSuccess}
                            onBeforeBuyNowButtonClick={handleBeforeBuyNowButtonClick}
                            onBuyNowButtonClick={handleBuyNowClick}
                            onGoToMarketplaceClick={handleOnClose}
                            onGoToShoppingCartClick={handleGoToShoppingCartClick}
                            onAddToCartButtonClick={handleAddToCartClick}
                            onAfterAddToCartModalClose={onAfterAddToCartModalClose}
                            onRewardQuestionClick={openRewardModal}
                            onPointsQuestionClick={(product) => openPointsModal([product])}
                            onBrandNameButtonClick={onBrandNameButtonClick}
                            onVariantChange={handleVariantChange}
                            rewardUuid={rewardUuid}
                        >
                            <CheckoutPDP
                                metadata={metadata}
                                extraContent={extraContent}
                                customizations={{
                                    ...customizations,
                                    ...(customizationsKey === 'shop'
                                        ? {
                                              buttons: {
                                                  showAddToCartButton: !referrer
                                              }
                                          }
                                        : {}),
                                    ...(customizationsKey === 'rewards'
                                        ? {
                                              buttons: {
                                                  showAddToCartButton: isLoggedIn,
                                                  showBuyNowButton: !isLoggedIn,
                                                  showBuyNowButtonBagIcon: true
                                              }
                                          }
                                        : {})
                                }}
                                testMode={account && account.giftly && account.giftly.testMode}
                                redirects={PDP_REDIRECTS[customizationsKey]}
                                bottomExtraContent={
                                    from !== 'onboarding' && (id || productId) ? (
                                        <RecommendedPromotions
                                            pending={store.recommendedPromotionsStore.loading}
                                            promotions={store.recommendedPromotionsStore.recommendedPromotions}
                                        />
                                    ) : null
                                }
                                useProductReviewNotification={(message, currentTab) =>
                                    useProductReviewNotification(store, message, currentTab)
                                }
                                useOnProductReviewSubmit={(data) => useOnProductReviewSubmit(store, data)}
                                notificationMessages={NOTIFICATION_MESSAGES}
                                customButtons={customButtons}
                                isLoggedIn={isLoggedIn}
                                inModal={inModal}
                            />
                        </CheckoutPDPHTTPWrapper>
                    </WrapStyles>
                </Styles>
            );
        }
    )
);
