import styled from 'styled-components';
import { $primary, $white } from '../../../../shared';
import { CouponWrapper } from '../../../../shared/components/CouponWrapper';
import { GRID_DEVICES_MAX } from '../../../../utils';

export const StyledWrapper = styled.div`
    background: ${$primary};
    height: 100%;
    //padding-top: 140px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-image: radial-gradient(ellipse, ${$white} 0%, ${$white} 55%, transparent 34%);
    background-size: 24px 24px;
    background-repeat: repeat-y;
    background-position: top 3px left -12px;
    position: relative;
    overflow: hidden;

    @media (${GRID_DEVICES_MAX.md}) {
        background: initial;
        padding-top: 40px;
    }

    .ant-row {
        max-width: 327px;
        margin: 0 auto;

        @media (${GRID_DEVICES_MAX.md}) {
            padding: 0 24px;
            max-width: initial;
        }
    }
`;

export const StyledCol = styled(CouponWrapper)``;
