import { getParent, types } from 'mobx-state-tree';
import { buildPromotionData } from '../shared/utils';
import { delay } from '../utils';

export const TrackingStore = types
    .model('TrackingStore', {
        ga4PurchaseDataMap: types.maybeNull(types.frozen())
    })
    .views((self) => ({
        get root() {
            return getParent(self);
        }
    }))
    .actions((self) => {
        const setGa4PurchaseDataMap = (data) => {
            self.ga4PurchaseDataMap = data;
        };

        const sendEvent = ({ type, name, data = {} }) => {
            if (window.klpixel) {
                window.klpixel(type, name, data);
            }
        };

        const trackViewPromotions = (promotions) => {
            if (!promotions || !promotions?.length) return null;

            return sendEvent({
                type: 'event',
                name: 'pageView',
                data: promotions.map((promotion) => {
                    const price = promotion.variants?.[0]?.price;
                    const qty = 1;

                    return {
                        marketplace: { promotion: buildPromotionData(promotion, { price, qty }) }
                    };
                })
            });
        };

        const trackViewPromotion = (promotion) => {
            if (!promotion) return null;

            return sendEvent({
                type: 'event',
                name: 'pageView',
                data: {
                    marketplace: { promotion: buildPromotionData(promotion) }
                }
            });
        };

        const trackAddToCart = (promotion, additionalData = {}) => {
            if (!promotion) return;

            return sendEvent({
                type: 'event',
                name: 'addToCart',
                data: [
                    {
                        marketplace: {
                            promotion: buildPromotionData(promotion, additionalData)
                        }
                    }
                ]
            });
        };

        const trackClick = (promotion, redirectToBrandLink) => {
            if (!promotion) return;

            const price = promotion.variants?.[0]?.price;
            const qty = 1;

            const row = {
                marketplace: {
                    promotion: buildPromotionData(promotion, { price, qty })
                }
            };

            if (redirectToBrandLink) {
                row.marketplace.redirectToBrandLink = redirectToBrandLink;
            }

            return sendEvent({
                type: 'event',
                name: 'click',
                data: [row]
            });
        };

        const trackChallengeNotification = (challenge, step, notificationBody) => {
            const data = {
                'Challenge ID': challenge?._id,
                'Challenge Name': challenge?.data?.title,
                'Challenge Alias': challenge?.alias,
                'Challenge Step no.': step,
                'Notification Text': notificationBody
            };

            const eventName =
                step === 'completed'
                    ? `Challenge_${challenge?.alias}_Complete`
                    : `Challenge_${challenge?.alias}_${step}`;

            sendEvent({ type: 'event', name: eventName, data });
        };

        const trackSeasonalChallengeNotification = (challenge, step, notificationBody) => {
            const data = {
                'Challenge Position': challenge?.position,
                'Challenge Name': challenge?.name,
                'Challenge Alias': challenge?.alias,
                'Challenge Step no.': step,
                'Notification Text': notificationBody
            };

            const eventName =
                step === 'completed'
                    ? `Challenge_${challenge?.alias}_Complete`
                    : `Challenge_${challenge?.alias}_${step}`;

            sendEvent({ type: 'event', name: eventName, data });
        };

        const sendEventPostMessage = ({ event, eventName, iframeId }) => {
            const dataMap = self.ga4PurchaseDataMap || {};
            const iframeIdSearch = event.data.iframeId || iframeId;

            const fromAnalytics = event.origin.includes('analytics') && event.origin.includes('klickly');
            if (!fromAnalytics) return;

            const scriptLoaded = event.data.action === 'ga4_loaded';
            if (!scriptLoaded) return;

            const isValidData = eventName === 'purchase' ? Object.keys(dataMap).includes(event.data.iframeId) : true;
            if (!isValidData) return;

            const eventData = dataMap[event.data.iframeId] || undefined;
            const iframe = document.getElementById(iframeIdSearch);
            iframe?.contentWindow?.postMessage({ name: eventName, data: eventData }, '*');
        };

        const sendGa4PostMessage = ({ eventName, iframeId }) => {
            const sendPostMessage = (event) => sendEventPostMessage({ event, eventName, iframeId });

            window.addEventListener('message', sendPostMessage);

            return () => removeEventListener('message', sendPostMessage);
        };

        const sendAddToCartPostMessage = ({ pixels = [], data, delayTime = 0 }) => {
            pixels.forEach((pixel) => {
                const iframe = document.getElementById(`kl_${pixel.name}_iframe`);

                if (iframe) {
                    let eventData = {};

                    if (pixel.name === 'tw') {
                        eventData = { add_to_cart: 1, item: data.productExternalId, qty: data.qty };
                    }

                    if (pixel.name === 'ga4') {
                        eventData = {
                            value: parseFloat(data.price),
                            currency: 'USD',
                            items: [{ item_id: data.productExternalId, item_name: data.itemName }]
                        };
                    }

                    iframe.contentWindow.postMessage({ name: 'add_to_cart', data: eventData }, '*');
                    return delay(delayTime);
                }
            });
        };

        return {
            trackViewPromotion,
            setGa4PurchaseDataMap,
            sendEvent,
            trackViewPromotions,
            trackAddToCart,
            trackClick,
            trackChallengeNotification,
            trackSeasonalChallengeNotification,
            sendGa4PostMessage,
            sendAddToCartPostMessage
        };
    });
