import { types } from 'mobx-state-tree';

export const RewardModel = types.model({
    _id: types.identifier,
    title: types.string,
    description: types.string,
    image: types.string,
    status: types.string,
    expireAt: types.string,
    promotions: types.maybeNull(
        types.array(
            types.model({
                _id: types.string,
                title: types.string,
                externalId: types.string,
                account: types.string,
                campaign: types.maybeNull(types.string),
                shopDomain: types.maybeNull(types.string),
                variants: types.maybeNull(
                    types.array(
                        types.model({
                            price: types.maybeNull(types.number),
                            title: types.maybeNull(types.string),
                            externalId: types.maybeNull(types.number),
                            externalProductId: types.maybeNull(types.number),
                            inventoryPolicy: types.maybeNull(types.string),
                            fulfillmentService: types.maybeNull(types.string),
                            weightUnit: types.maybeNull(types.string),
                            weight: types.maybeNull(types.number),
                            originalPrice: types.maybeNull(types.number),
                            position: types.maybeNull(types.number),
                            requiresShipping: types.maybeNull(types.boolean),
                            quantity: types.maybeNull(types.number)
                        })
                    )
                )
            })
        )
    )
});
