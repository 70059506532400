import React from 'react';
import { StyledBorderLeft, StyledBorderRight, StyledWavesBottom, StyledWavesTop, StyledWrap } from './styled';

export const CouponWrapper = ({ children, hideBorders = false, ...props }) => (
    <StyledWrap {...props}>
        <StyledBorderLeft style={hideBorders ? { display: 'none' } : {}} />
        <StyledWavesTop style={hideBorders ? { display: 'none' } : {}} />
        {children}
        <StyledWavesBottom style={hideBorders ? { display: 'none' } : {}} />
        <StyledBorderRight style={hideBorders ? { display: 'none' } : {}} />
    </StyledWrap>
);
